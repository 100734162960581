.container-megaContainerVendas{
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: var(--font-family);
}

.megaContainerVendas-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
}

.megaContainerVendas-content__title{
    text-align: center;
}

.megaContainerVendas-content__title h1{
    font-size: 44pt;
    color: #545454;
}

.megaContainerVendas-content__cards{
    display: flex;
    align-items: center;
    margin-top: 3rem;

    overflow: hidden;
    color:  #2C445F;
}

.cards-container{
    display: flex;
}

@media screen and (max-width: 700px){
    .megaContainerVendas-content__cards{
        flex-direction: column;
    }

    .megaContainerVendas-content{
        padding: 0rem  2rem;
        max-width: 350px;
    }
    
    .megaContainerVendas-content__title h1{
        font-size: 28pt;
    }

    .cards-container{
        width: 500px;
        margin-left: 5rem;
    }
}