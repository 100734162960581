h1{
    padding: 0;
    margin: 0;
}
.container-solution{
    display: flex;
    align-items: center;
    justify-content: center;
}

.solution-content{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.solution-content_image{
    display: flex;
    justify-content: center;
    align-items: center;
}

.solution-content_image img{
    margin-top: -370px;
    z-index: 0;
}

@media screen and (max-width: 700px){
    .solution-content_image{
        width: 100%;
    }
    .solution-content_image img{
        width: 22rem;
        margin-top: -180px;
    }
}