.container-negocios{
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    margin-top: -250px;
}

.negocios-content{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1920px;
}

.negocios-content_text{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;

    width: 950px;
}

.negocios-content_text h1{
    color: #545454;
    font-size: 42pt;
    font-family: var(--font-family);
    font-weight: 600;
    line-height: 4rem;
}

.negocios-content p{
    color: #545454;
    font-size: 15pt;
    font-family: var(--font-family);
    margin-top: 30px;

    line-height: 1.5rem;
}

.negocios-content_cards-vendas{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;

    width: 1360px;
    height: 500px;
}

.cards_content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    color: #2C445F;
    font-family: var(--font-family);

    width: 370px;
    height: 380px;

    margin: 52px;
    border-radius: 25px;

    -webkit-box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.62); 
    box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.62);
}

.cards_content p{
    line-height: 1.5rem;
    padding: 0rem 2.5rem 1.5rem 2.5rem;
}


.cards_content h2{
    font-size: 32pt;
    font-weight: 600;
}

.cards-title{
    flex: 1;
    padding-top: 2rem;
    display: flex;
 }
 
 .cards-descricao{
     flex: 2;
     display: flex;
 }
 
 .cards-link{
     flex: 1;
     display: flex;
     align-items: flex-end;
     margin-bottom: 2rem;
 }

 @media screen and (max-width: 1360px){
    .negocios-content_cards-vendas{
        width: 1180px;
    }

    .cards_content{
        width: 310px;
        height: 310px;
    }

    .cards_content h2{
        font-size: 24pt;
    }

    .cards_content p{
        font-size: 11pt;
    }


 }

@media screen and (max-width: 700px){
    .negocios-content{
        padding: 8rem 2rem;
    }

    .negocios-content_text{
        width: 90%;
    }

    .negocios-content_text h1{
        font-size: 28pt;
        line-height: 3rem;
    }

    .negocios-content_text p{
        line-height: 1.25rem;
        margin-bottom: -1.8rem;
    }

    .negocios-content_cards-vendas{
        width: 100%;
    }

    .cards_content{
        width: 75.5vw;
        height: 350px;
    }

    .cards_content h2{
        font-size: 24pt;
    }

    .cards_content p{
        font-size: 11pt;
    }
}

@media screen and (max-width: 380px){
    .cards_content{
        width: 73vw;
        height: 350px;
    }

    .cards_content h2{
        font-size: 20pt;
    }

    .cards_content p{
        font-size: 10pt;
        padding: 0 1.5rem 1.5rem 1.5rem;
    }
}
