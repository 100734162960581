.container-sobremaps{
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: var(--font-family);
    font-weight: 500;
}

.sobremaps-content{
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sobremaps-content__cards{
    display: flex;
    width: 1366px;
    justify-content: space-between;
}

.card-whats, .card-location, .card-email{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
    z-index: 1;
}

.card-location{
    flex: 0.5;
    padding: 0.75rem;
    border-radius: 25px;
    margin-bottom: -50px;
    background-color: #ffffffbe;
    
}

.card-whats img, .card-location img, .card-email img{
    width: 100px;
}

.card-whats p, .card-location p, .card-email p{
    padding-top: 1rem;
    font-size: 14pt;
}

.sobremaps-content__maps{
    display: flex;
    width: 98vw;
}


@media screen and (max-width: 700px){
    .sobremaps-content{
        max-width: 400px;
        padding: 2rem 2rem;
    }

    .sobremaps-content__cards{
        flex-direction: column;
        max-width: 360px;
    }

    .card-location{
        margin: 2rem 0rem;
    }

    .card-email{
        margin-bottom: 2rem;
    }

    .sobremaps-content__maps{
        width: 100%;
    }
    
}