.section__card{
    display: flex;
    width: 450px;
    height: 2.5rem;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    background-color:  #64CDF5;
    border-radius: 40px;
    margin-bottom: 2rem;
}

.card-title{
    flex: 3;
    display: flex;
    width: 100%;
}

.card-image{
    flex: 1;
    display: flex;
    width: 100%;
}

.section__card p{
    font-weight: 600;
    font-size: 15pt;
}

.section__card img{
    width: 55px;
    margin-left: 2rem;
}

.reverseFlex{
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
}

.reverseImg{
    width: 480px;
    display: flex;
    position: absolute;
}

.reverseImg img{
    margin-left: -20px;
    width: 55px;
    animation: none;
}

.reverseTitle{
    display: flex;
    justify-content: flex-end;
}

.image {
    width: 480px;
    display: flex;
    justify-content: flex-end;
    position: absolute;

}

.image img{
    right: 0;
}

@media screen and (max-width: 1360px){
    .section__card{
        width: 430px;
    }

    .reverseImg img{
        margin-left: -10px;
        width: 55px;
        animation: none;
    }

    .image {
        margin-left: -20px;
    }
    .section__card p{
        font-size: 13pt;
    }

}

@media screen and (max-width: 700px){
    .section__card{
        width: 20em;
    }
    .section__card p{
        font-size: 11pt;
    }
    .reverseFlex{
        flex-direction: row;
    }
    .reverseImg{
        position: unset;
        width: 100%;
        margin-right: 0px;
    }
    
    .card-title{
        justify-content: flex-start;
    }

    .image {
        width: 100%;
        position: unset;
    }
}

@media screen and (max-width: 420px){
    .section__card p{
        font-size: 10pt;
    }
    .reverseImg img{
        margin-left: 25px;
    }
}
