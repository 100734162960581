.scroll{
    display: flex;
    flex-direction: column;

    overflow: hidden;
}

body::-webkit-scrollbar {
    width: 12px;               
  }
  
body::-webkit-scrollbar-track {
    background: rgb(224, 224, 224);        
  }
  
body::-webkit-scrollbar-thumb {
    background-color:  #2C78A1;
    border-radius: 20px;       
    border: 3px solid rgb(224, 224, 224);
}