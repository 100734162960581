.container-valores{
    display: flex;
    justify-content: center;
    align-items: center;  
    font-family: var(--font-family);
}

.valores-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.valores-content__title h1{
    font-size: 48pt;
    color: #545454;
    margin-bottom: 2rem;
}

.valores-content__section{
    display: flex;

    background-image: url('../../../assets/valoresBackground.png');
}

.valores-content__cards{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.valores-content__imagem{
    flex: 1;
    display: flex;
}

.valores-content__imagem img{
    width: 100%;
}

.card{
    display: flex;
    justify-content: center;
    
    width: 250px;
    height: 40px;
    padding: 0.5rem;
    margin: 1.5rem 0rem;

    border-radius: 50px;
    background-color: #545454;
}

.card h1{
    font-weight: 500;
    font-size: 18pt;
}

#primeiro{
    background-color: rgb(168, 62, 105);
}

#segundo{
    background-color: rgb(128, 187, 230);
}

#terceiro{
    background-color: rgb(96, 117, 184);
}

#quarto{
    background-color: rgb(20, 110, 170);
}

@media screen and (max-width: 700px){
    .valores-content{
        padding: 2rem 2rem;
    } 
    .valores-content__section{
        flex-direction: column;
    }
    .valores-content__imagem{
        display: flex;
        width: 100%;
    }

    .valores-content__title h1{
        font-size: 30pt;
        text-align: center;
    }

    .valores-content__imagem img{
        width: 100%;
    }
}