.container-servicos{
    display: flex;
    width: 100vw;
    font-family: var(--font-family);
    align-items: center;
    justify-content: center;
}

.servicos-content{
    display: flex;
    width: 100%;
    max-width: 1366px;
    align-items: center;
    flex-direction: column;
}

.servicos-title{
    color: #545454;
    font-weight: 600;
    font-size: 44pt;
    margin-bottom: 5rem;
}

.servicos-content h1, p{
    color: #2C445F;
}

@media screen and (max-width: 700px){
    .servicos-content{
        padding: 0rem 2rem;
        text-align: center;
    }
    .servicos-title{
        width: 100%;
        font-weight: 600;
        font-size: 34pt;

        line-height: 3.5rem;
    }
}

