.container-vendasComp{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
}

.vendasComp-content{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
}

