.none{
    height: 0px;
}

.cookie-container{
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100vw;
    bottom: 0;
    right: 0;
    z-index: 100;

    font-family: var(--font-family);
    color:white;
    background: rgb(4, 20, 59);

    -webkit-box-shadow: 2px 4px 38px -14px rgba(255,255,255,1);
    -moz-box-shadow: 2px 4px 38px -14px rgba(255,255,255,1);
    box-shadow: 2px 4px 38px -14px rgba(255,255,255,1);

    animation: fadeInFromNone 0.8s ease-out;
}

.cookie-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    width: 100%;
    height:75px;
}

.buttons-container{
    display: flex;
    align-items: center;
}

.buttons-container button{
    width: 120px;
    border-radius: 10px;
    border: none;
    height: 35px;
    margin: 0px 10px;
}

.button-aceitar{
    background-color: cyan;
    font-weight: 500;
    color: black;
}

.button-aceitar:hover{
    cursor: pointer;
    background-color: rgb(3, 196, 196);
}

.reject-button{
    padding: 5px 25px;
    border: 3px solid transparent;
    border-radius: 10px;
}

.reject-button:hover{
    cursor: pointer;
    border: 3px solid white;
}

@media screen and (max-width: 768px){
    .cookie-content{
        flex-direction: column;
        height: 100%;
        padding: 30px;
    }

    .cookie-content h5{
        text-align: center;
    }

    .buttons-container{
        padding: 20px;
    }
}

@keyframes fadeInFromNone {
    0% {
        height: 0px;
    }

    100% {
        height: 75px;
    }
}