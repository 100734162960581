.container-vendasComp{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
}

.vendasComp-content{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
}

@media screen and (max-width: 700px){
    .vendasComp-content{
        padding: 2rem 2rem;
    }
}


