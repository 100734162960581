.megaCard{
    width: 300px;
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    padding: 1rem;
    margin: 2rem;

    -webkit-box-shadow: 0px 0px 14px 2px rgba(0,0,0,0.62); 
    box-shadow: 0px 0px 14px 2px rgba(0,0,0,0.62);
}

.megaCard img{
    width: 100px;
}

.megaCard h2{
    font-size: 28pt;
    line-height: 2.5rem;

    padding: 2rem 0rem;
}

.megaCard p{
    font-size: 15pt;
}

.megaCard span{
    font-weight: 800;
}

@media screen and (max-width: 700px){
    .megaCard{
        margin-right: 0.5rem;
    }

    .megaCard h2{
        font-size: 28pt;
    }

}
