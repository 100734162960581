.navbar{
    display: flex;
    position: fixed;
    align-items: center;
    width: 100vw;
    height: 80px;
    z-index: 10;
}

.navbar-bg{
  background-color: #2c4a6ba4;
}

.navbar-content{
    display: flex;
    width: 100%;
    height: 20px;
    justify-content: space-between;
    align-items: center;
}

.navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#active{
  color: #64CDF5;
}

.navbar-links a:hover{
    color:#64CDF5;
}

.home, .sobrenos{
    color: #64CDF5;
}

.navbar-links_logo{
    margin-right: 2rem;
}

.navbar-links_logo img{
    width: 5rem;
    height: 5rem;
}

.navbar-links_container{
    display: flex;
    flex-direction: row;
}

.navbar-links_container p{
    color: #fff;
    padding-right: 1rem;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 15pt;
}

.none{
    display: none;
}

.navbar-menu-icon{
    display: none;
    color: #fff;
}

.icon-navbar{
    font-size: 1.75rem;
    -webkit-animation: rotate-90-cw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) alternate-reverse forwards;
	        animation: rotate-90-cw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) alternate-reverse forwards;
}

.arrowIcon{
  font-size: 15pt;
  margin-top: 2px;
  margin-right: 1rem;
}

/*Dropdown Navbar*/

.dropdown {
    float: left;
    overflow: hidden;
    font-family: var(--font-family);
}

.dropbtn {
  display: flex;
  width: 100%;
  align-items: center;
}

.dropbtn p {
  padding-right: 0.4rem;
}

.dropdown .dropbtn {
    border: none;
    outline: none;
    color: white;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */

    cursor: pointer;
  }
  
  .dropbtn:hover, .dropbtn p:hover{
    color:#64CDF5;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #2c4a6ba4;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-left: -5px;
    border-radius: 0px 0px 15px 15px;
  }

  .dropdown-content a {
    float: none;
    color: rgb(255, 255, 255);
    padding: 5px 3px;
    text-decoration: none;
    display: block;
    text-align: left;

    cursor: pointer;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

@media screen and (max-width: 1050px){
    .navbar-links {
        display: none;
    }
    .navbar-links_container-mobile{
        display: flex;
        width: 100%;
    }
    .navbar-links_container{
        display: flex;
        justify-content: center;
        width: 100%;
        left: 0;
        
        background-color:  #2c4a6ba4;
        position: absolute;
        flex-direction: column;
        margin-top: -60px;
        padding: 1rem 0rem;
        
        align-items: center;
        z-index: 10;

        -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .navbar-menu{
        display: flex;
    }

    .navbar-menu-icon{
        display: flex;
        color: #fff;
    }

    .icon-navbar-close{
        font-size: 1.75rem;
        display: flex;
        -webkit-animation: rotate-90-cw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) alternate forwards;
        animation: rotate-90-cw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) alternate forwards;
    }

    .navbar-links_logo img{
        width: 3.5rem;
        height: 3.5rem;
    }
}

@media screen and (max-width: 700px){
    .navbar-content{ 
        padding: 2rem 2rem;
    }

    .navbar-links_container{
      box-shadow: none;
    }

    .dropdown-content {
        position: unset;
        background-color: #2c4c6b00;
        line-height: 1rem;
    }
    
    
}

@-webkit-keyframes rotate-90-cw {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
    }
  }
  @keyframes rotate-90-cw {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
    }
  }

  /**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
    }
  }
  @keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
    }
  }