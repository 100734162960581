.container-chama{
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: var(--font-family);
}

.chama-content{
    display: flex;
    justify-content: center;
    align-items: center;
}

.chama-content__section-left{
    flex: 1;
    display: flex;
    
}

.chama-content__section-left img{
    width: 100%;
    max-width: 600px;
    max-height: 600px;
}

.chama-content__section-right{
    flex: 1;
    display: flex;
    max-width: 550px;
    flex-direction: column;
    text-align: center;
}

.chama-content__section-right h1{
    font-size: 48pt;
    color: #545454;
    padding-bottom: 1rem;
}

.chama-content__section-right p{
    padding-bottom: 2rem;
}

@media screen and (max-width: 700px){
    .chama-content{
        padding: 2rem 2rem;
    }

    .chama-content__section-left{
        flex: 0;
    }
    .chama-content__section-right h1{
        font-size: 30pt;
    } 

    .chama-content__section-right p{
        font-weight: 500;
    }
}
