.container-vantagens{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    font-family: var(--font-family);
}

.vantagens-content{
    display: flex;
    width: 100%;

    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.vantagens-content__title h1{
    color: #5e165a00;
    font-size: 44pt;
    -webkit-text-stroke: 2px  #64CDF5;
    margin-bottom: 3rem;
}

.vantagens-content__section{
    display: flex;
    width: 100%;
    max-width: 1366px;
    justify-content: center;
    align-items: center;
}

.section-left{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-right{
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 700px){
    .vantagens-content{
        padding: 2rem 1rem;
    }
    .vantagens-content__section{
        flex-direction: column;
    }

    .vantagens-content__title h1{
        font-size: 32pt;
    }
}