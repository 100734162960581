.container-duvidas{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;

    font-family: var(--font-family);
}

.duvidas-content{
    display: flex;
    width: 100%;
    max-width: 1366px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.duvidas-content__title-component{
    display: flex;
    flex-direction: column;
    align-items: center;

    line-height: 3rem;
    margin-bottom: 1.5rem;
}

.duvidas-content__title-component h1{
    color: transparent;
    font-size: 52pt;
    -webkit-text-stroke: 2px  #2C78A1;
    padding-bottom: 2rem;
}

.duvidas-content__sumario{
    display: flex;
    width: 100%;
    justify-content: space-between;

    font-weight: 600;
    font-size: 14pt;
}

@media screen and (max-width: 700px){
    .duvidas-content{
        padding: 0rem 2rem;
        text-align: center;
    }

    .duvidas-content__title-component h1{
        font-size: 34pt;
        margin-top: 4rem;
    }

    .duvidas-content__sumario{
        flex-direction: column;
    }
}

