.container-duvidas{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;

    font-family: var(--font-family);
}

.duvidas-content{
    display: flex;
    width: 100%;
    max-width: 1366px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.duvidas-content__title{
    display: flex;
    flex-direction: column;
    align-items: center;

    line-height: 3rem;
    margin-bottom: 1.5rem;
}

.duvidas-content__title h1{
    color: #545454;
    font-size: 48pt;
}

.duvidas-content__title p{
    font-size: 14pt;
}

.duvidas-content__sumario{
    display: flex;
    width: 100%;
    justify-content: space-between;

    font-weight: 600;
    font-size: 14pt;
}


@media screen and (max-width: 700px){
    .duvidas-content{
        padding: 0rem 2rem;
        text-align: center;
    }

    .duvidas-content__title h1{
        font-size: 34pt;
        margin-top: 4rem;
    }

    .duvidas-content__title p{
        line-height: 1.5rem;
        font-weight: 500;
        margin-top: 1rem;
    }

    .duvidas-content__sumario{
        flex-direction: column;
    }
}

/**
 * ----------------------------------------
 * animation shadow-drop-center
 * ----------------------------------------
 */
 @-webkit-keyframes shadow-drop-center {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
              box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }
  @keyframes shadow-drop-center {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
              box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }
