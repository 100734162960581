@import url("https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap");

*,
*:before,
*:after {
  box-sizing: inherit;
}


details{
  display: flex;
  font-size: 1rem;
  margin: 2em auto;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.30); 
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.30);
  width: 100%;
  background: #ffffff;
  border-radius: 25px;
  position: relative;
  width: 550px;

  color:  #2C445F;

  font-family: var(--font-family);
  
}

details:hover{
    cursor: pointer;
    animation: scale-up-center-nova 1s linear;
}

.summary-title {
    user-select: none;
}

.summary-content {
    cursor: default;
    padding: 1em;
    font-weight: 300;
    line-height: 1.5;
    
}

summary {
    list-style: none;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    
}

.summary-chevron-up{
    display: flex;
    align-items: center;
    margin-top: 5px;
    justify-content: center;
    
}

summary:focus {
      outline: none;
      
}

summary:hover, .summary-chevron-up svg {
      opacity: 1;
}

.summary-chevron-up svg {
    opacity: 0.5;
}

.summary-chevron-up,
  .summary-chevron-down {
    pointer-events: none;
    position: absolute;
    top: 0.75em;
    right: 1em;
    
  }
  svg {
    display: flex;
  }

  summary::-webkit-details-marker {
    display: none;
  }

@media screen and (max-width: 1360px){
  details{
    width: 450px;
  }
}


@media screen and (max-width: 700px){
    details{
        width: 100%;
    }

    .summary-chevron-up{
        position: unset;
        display: flex;
    }

    .summary-chevron-down {
        top: 1.85em
    }
}

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center-nova {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
  }
  100% {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
  }
}
@keyframes scale-up-center-nova  {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
