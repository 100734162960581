.container-contato{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;

    background-color: #2C78A1;
    font-family: var(--font-family);
}

.contato-content{
    display: flex;
    width: 100%;

    max-width: 1366px;
}

.contato-content__left{
    flex: 1;
    display: flex;
}

.contato-content__right{
    flex: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.contato-right__inputs{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contato-content__title{
    display: flex;
    flex-direction: column;
    width: 500px;
}

.contato-content__title h1{
    font-size: 48pt;
    color: #fff;
    line-height: 4.5rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.contato-content__title p{
    color:#fff;
    font-size: 14pt;
    font-weight: 200;

    line-height: 1.25rem;
}

.contato-content__paragrafo{
    width: 350px;
}

.input-right{
    padding: 1.5rem;
    margin-bottom: 1.5rem;

    border: none;
    border-radius: 35px;

    color:#124a77;

    font-size: 16pt;
    font-family: var(--font-family);
    font-weight: 500;
}


.contato-content__right button{
    height: 4rem;
    width: 200px;
    padding: 0rem 1rem 0rem 1rem;
    color: black;
    font-weight: bold;
    background-color: #00C4CC;
    border: none;
    border-radius: 35px;
    font-size: 15pt;
    font-weight: 500;

    cursor: pointer;
    z-index: 1;
}

.contato-content__right button:hover{
  background-color: #02b2b8;
}

.right-chekbox{
    display: flex;
    align-items: center;
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.right-chekbox p{
    color: #fff;
    margin-left: 0.5rem;
    margin-bottom: 1.5rem;
}

.right-chekbox input{
  cursor: pointer;
}


/* ---------  Check Box ----------- */
/* Customize the label (the container) */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  @media screen and (max-width: 700px){
    .contato-content{
      width: 85%;
      padding: 2rem 2rem;
      flex-direction: column;
      text-align: center;
    }

    .contato-content__title{
      width: 100%;
    }

    .contato-content__title h1{
      font-size: 28pt;
      line-height: 2.25rem;
      font-weight: 600;
    }

    .input-right{
      padding: 1rem;
      font-size: 14pt;
    }

    .contato-content__paragrafo{
      margin-bottom: 2rem;
      width: 100%;
    }

    .contato-content__right{
      align-items: center;
    }

    .checkmark{
      width: 20px;
      height: 20px;
    }

    .right-chekbox{
      width: 100%;
      text-align: left;
      margin-left: 60px;
    }
    .right-chekbox p{
      font-size: 14pt;
      margin-left: 0px;
      margin-top: -5px;
    }
    
  }