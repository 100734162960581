.section-content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vendasComp-content__left{
    flex: 1;
    display: flex;
    width: 100%;
    justify-content: center;
}

.vendasComp-content__right{
    flex: 1;
    display: flex;
    justify-content: center;
}


.left-content{
    width: 400px;

    text-align: left;
}

.left-content__title{
    display: flex;
    width: 100%;
}

.vendasComp-content__left h1{
    font-size: 44pt;
    color:  #2C445F;

    font-weight: 500;
}

.vendasComp-content__left p{
    font-size: 13pt;
    font-weight: 400;

    margin-top: 2rem;
}

.vendasComp-content__left button{
    width: 24rem;
    height: 3.5rem;
    padding: 0rem 1rem;
    margin-top: 3rem;

    background-color: #00C4CC;
    color: black;

    border: none;
    border-radius: 35px;

    font-size: 14pt;
    font-weight: 500;

    cursor: pointer;
}

.vendasComp-content__right img{
  
    height: 450px;
}


@media screen and (max-width: 700px){
    .section-content{
        flex-direction: column;
    }

    .vendasComp-content__right img{
        width: 100%;
        height: 100%;
        margin-top: 3rem;
    }

    .left-content{
        width: 100%;

        text-align: center;
    }

    .left-content h1{
        font-size: 32pt;
    }   

    .left-content button{
        width: 18rem;
        margin-left: 0px;
    }

    .left-content__title{
        justify-content: center;
    }
}