.container-citacoes{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;

    font-family: var(--font-family);
}

.citacoes-content{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    max-width: 1366px;

}

.citacoes-content__title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 22pt;
    line-height: 3.5rem;
}

.citacoes-content__text-middle{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: self-start;

    color: #00C4CC;
}

.citacoes-content__text-middle p{
    color: #00C4CC;
    font-size: 15pt;
}

.text-middle-inner{
    display: flex;
}

.text-middle__content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}

.middle__content-image{
    display: flex;
    width: 100%;
    margin-left: 5rem;
}

.text-middle__content-end{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content:last baseline;
    align-items: flex-start;

    margin-left: 4.5rem;
    margin-top: 50px;
}

.text-middle__content img{
    width: 40px;
    margin-bottom: 2rem;
    animation: updownCitacoes 1s linear infinite;
}

.text-middle__content-middle{
    display: flex;
    align-items: center;
    width: 85%;
    height: 150px;
}

@media screen and (max-width: 700px){
    .citacoes-content{
        padding: 4rem 1rem;
        overflow: hidden;
    }
    .citacoes-content__title{
        font-size: 18pt;
        text-align: center;
    }

    .text-middle__content-middle{
        width: 100%;
        justify-content: center;
        align-items: center;
        padding-right: 0;
    }

    .citacoes-content__text-middle{
        width: 110%;
    }

    .citacoes-content__text-middle p{
        font-size: 12pt;
        font-weight: 500;
        text-align: center;
    }

    .text-middle__content-end, .middle__content-image{
        margin-left: 0vw;
    }

    .text-middle-inner{
        display: flex;
    }

    
    .citacoes-content__text-middle p, .text-middle__content{
        color: #2C445F;
    }

    .text-middle__content-end{
        align-items: center;
    }

    .text-middle__content-end h2{
        font-size: 14pt;
    }
    
    .text-middle__content{
        width: 73.5vw;
        height: 350px;
        padding: 1rem;
        background-color: azure;
        border-radius: 30px;
        margin-left: 2.5em;
        margin-bottom: 3em;
    }
}

@media screen and (max-width: 380px){
    .citacoes-content__text-middle{
        width: 111%;
    }

    .text-middle__content{
        width: 73vw;
    }
}

@media screen and (max-width: 390px){
    .citacoes-content__text-middle{
        width: 112.5%;
    }
}

@media screen and (max-width: 360px){
    .citacoes-content__text-middle{
        width: 111%;
    }
    .text-middle__content{
        width: 69.5vw;
    }
}

@keyframes updownCitacoes {
    0% {
        transform: translateY(-1.5px);
    }
    50% {
        transform: translateY(1.5px);
    }
    100% {
        transform: translateY(-1.5px);
    }
}
