ol{
    display: flex;
    flex-direction: column;
    width: 450px;
}
li{
    width: 100%;
    color: #5e165a00;
    font-size: 40pt;
    -webkit-text-stroke: 2px  #2C78A1; 
    font-weight: 900;
}
