.container-segmentosComp{
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;

    font-family: var(--font-family);
    overflow: hidden;
}

.segmentosComp-content{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1920px;
}

.segmentosComp-content_text{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.segmentosComp-content_text h1{
    color: #545454;
    font-size: 48pt;
    font-weight: 600;
    line-height: 4rem;
}

.segmentosComp-content p{
    color: #545454;
    font-size: 15pt;
    margin-top: 30px;
    font-weight: 300;
    line-height: 1rem;
}

.segmentosComp-content_cards-vendas{
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 100px;
    width: 1360px;
}

.segmentosComp__cards_content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-color:  #2C78A1;
    color: #fff;

    width: 370px;
    height: 380px;
    margin: 52px;
    text-align: center;

    border-radius: 25px;

    -webkit-box-shadow: 0px 0px 50px 0px rgba(105,104,105,0.66);
    -moz-box-shadow: 0px 0px 50px 0px rgba(105,104,105,0.66);
    box-shadow: 0px 0px 50px 0px rgba(105,104,105,0.66);
}

.segmentosComp__cards_content p{
    line-height: 1.5rem;
    padding: 0rem 2.5rem 1.5rem 2.5rem;
    color: #ffff;
}

.segmentosComp__cards_content h2{
    font-size: 30pt;
    font-weight: 600;
}

.segmentosComp__cards-title{
   flex: 1;
   padding-top: 2rem;
   display: flex;
}

.segmentosComp__cards-descricao{
    flex: 2;
    display: flex;
}

.segmentosComp__cards-link{
    flex: 1;
    display: flex;
    align-items: flex-end;
    margin-bottom: 2rem;
}

@media screen and (max-width: 1360px){
    .segmentosComp-content_cards-vendas{
        width: 1180px;
    }

    .segmentosComp__cards_content{
        width: 310px;
        height: 310px;
    }

    .segmentosComp__cards_content h2{
        font-size: 24pt;
    }

    .segmentosComp__cards_content p{
        font-size: 11pt;
    }

 }

@media screen and (max-width: 700px){
    .segmentosComp-content{
        padding: 8rem 2rem;
    }

    .segmentosComp-content_text h1{
        font-size: 34pt;
        line-height: 3rem;
    }

    .segmentosComp-content_text p{
        line-height: 1.25rem;
        margin-bottom: -1.8rem;
    }

    .segmentosComp-content_cards-vendas{
        width: 105%;
        overflow: hidden;
    }

    .segmentosComp__cards-vendas_container{
        flex-direction: row;
        align-items: center;
        margin-left: 24rem;
    }

    .segmentosComp-vendas_container{
        margin-left: 23rem;
    }

    .segmentosComp__cards_content{
        width: 84vw;
        margin-right: 1rem;
        margin-top: 1rem;
        margin-bottom: 3rem;
    }
}

@media screen and (max-width: 420px){
    .segmentosComp-content_cards-vendas{
        width: 100%;
    }

    .segmentosComp__cards_content{
        width: 75.8vw;
    }
}

@media screen and (max-width: 395px){
    .segmentosComp__cards_content{
        width: 73.8vw;
    }
}

@media screen and (max-width: 360px){
    .segmentosComp__cards_content{
        width: 72vw;
    }

    .segmentosComp__cards_content h2{
        font-size: 18pt;
        line-height: 3rem;
    }

    .segmentosComp__cards_content p{
        font-size: 10pt;
    }
}
