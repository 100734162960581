.reverse{
    flex-direction: row-reverse;
    
}

.justify{
    justify-content: left;
}

.reverseJustify{
    justify-content: left;
}

.reverseText{
    text-align: right;
}

.imagem-right{
    display: flex;
    width: 100%;
}

.imagem-left{
    display: flex;
    width: 100%;
    justify-content: flex-end;

}

.text{
    text-align: left;
}

.title-left, .subtitle-left, .link-left{
    display: flex;
    width: 100%;
}

.title-right, .subtitle-right, .link-right{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.projectAnim{
    width: 100%;
    height: 100%;
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 1s;
}

.projectAnimReverse{
    width: 100%;
    height: 100%;
    opacity: 0;
    filter: blur(5px);
    transform: translateX(100%);
    transition: all 1s;
}

.show{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  }

.servicos-content_section{
    display: flex;
    padding-bottom: 4rem;
}

.servicos-content_section h1{
    font-weight: 500;
    font-size: 40pt;
    margin-bottom: 1rem;
}

.servicos-content_section h2{
    color: #2C445F;
}

#section-subtitle{
    font-size: 17pt;
    font-weight: 600;
}

.servicos-content_section p{
    font-size: 16pt;
    padding-bottom: 1rem;
}

.section-left{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section-right{
    flex: 1;
    display: flex;
}

.servicos-content_title h1{
    color: #545454;
    font-size: 48pt;
}

.section-right img{
    animation: scale-up-center 4s linear infinite;
    width: 330px;
}

.servicos-content_section{
    justify-content: space-between;
}

@media screen and (max-width: 700px){
    .servicos-content_section{
        flex-direction: column;
    }

    .text, .reverseText{
        align-items: center;
        text-align: center;
    }

    .title-left, .subtitle-left, .link-left, .title-right, .subtitle-right, .link-right{
        text-align: center;
        display: block;
    }

    .servicos-content_section h1{
        font-size: 35pt;
    }

    .section-left, .section-right h1{
        line-height: 3rem;
    }

    .servicos-content_section p{
        line-height: 1.75rem;
    }
}

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    50%{
        -webkit-transform: scale(0.9);
        transform: scale(0.9); 
    }
    100% {
        -webkit-transform: scale(0.85);
        transform: scale(0.85);
    }
  }
  
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.85);
              transform: scale(0.85);
    }
    50%{
        -webkit-transform: scale(0.9);
        transform: scale(0.9); 
    }
    100% {
      -webkit-transform: scale(0.85);
              transform: scale(0.85);
    }
  }

  @keyframes updown {
    0% {
        transform: translateX(-20px);
    }
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(-20px);
    }
}