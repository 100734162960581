.container-conhecaComponent{
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;

    font-family: var(--font-family);
    overflow: hidden;
}

.conhecaComponent-content{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1920px;
}

.conhecaComponent-content_text{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.conhecaComponent-content_text h1{
    color: #545454;
    font-size: 48pt;
    font-weight: 600;
    line-height: 4rem;
}

.conhecaComponent-content p{
    color: #545454;
    font-size: 15pt;
    margin-top: 30px;
    font-weight: 300;
    line-height: 1rem;
}

.conhecaComponent-content_cards-vendas{
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 100px;
    width: 1360px;
    height: 455px;
    overflow: hidden;
}

.conhecaoComponent__cards_content p{
    line-height: 1.5rem;
    padding: 0rem 2.5rem 1.5rem 2.5rem;
    color: #ffff;
}

.conhecaoComponent__cards_content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-color:  #2C78A1;
    color: #fff;
    text-align: center;

    margin: 52px;

    width: 370px;
    height: 380px;

    border-radius: 25px;

    -webkit-box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.62); 
    box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.62);
}

.conhecaoComponent__cards_content h2{
    font-size: 30pt;
    font-weight: 600;
}

.conhecaoComponent__cards-title{
   flex: 1;
   padding-top: 2rem;
   display: flex;
}

.conhecaoComponent__cards-descricao{
    flex: 2;
    display: flex;
}

.conhecaoComponent__cards-link{
    flex: 1;
    display: flex;
    align-items: flex-end;
    margin-bottom: 2rem;
}

@media screen and (max-width: 1360px){
    .conhecaComponent-content_cards-vendas{
        width: 1180px;
    }

    .conhecaoComponent__cards_content{
        width: 310px;
        height: 310px;
    }

    .conhecaoComponent__cards_content h2{
        font-size: 24pt;
    }

    .conhecaoComponent__cards_content p{
        font-size: 11pt;
    }

 }


@media screen and (max-width: 700px){
    .conhecaComponent-content{
        padding: 8rem 2rem;
    }

    .conhecaComponent-content_text h1{
        font-size: 34pt;
        line-height: 3rem;
    }

    .conhecaComponent-content_text p{
        line-height: 1.25rem;
        margin-bottom: -1.8rem;
    }

    .conhecaComponent-content_cards-vendas{
        width: 105%;
    }

    .conhecaoComponent__cards_content{
        width: 84vw;
        margin-right: 1rem;
        margin-top: 1rem;
        margin-bottom: 3rem;
    }
}

@media screen and (max-width: 414px){
    .conhecaComponent-content_cards-vendas{
        width: 100%;
    }

    .conhecaoComponent__cards_content{
        width: 75.8vw;
    }
}

@media screen and (max-width: 380px){
    .conhecaoComponent__cards_content{
        width: 73vw;
    }

    .conhecaoComponent__cards-title h2{
        font-size: 22pt;
    }

    .conhecaoComponent__cards-descricao p{
        font-size: 10pt;
    }

}
