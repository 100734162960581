.container-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100vw;
}

.header-content{
    display: flex;
    width: 100%;
    height: 80vh;
    margin-top: 2rem;
    justify-content: center;
    align-items: space-between;
    flex-direction: column;

    max-width: 1920px;

}

.header_content__todo{
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-content__left{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-right: 5rem;
}

.left-section{
    display: flex;
    flex-direction: column;
    max-width: 500px;
}

.header-content__left h1{
    font-size: 3.5rem;
    line-height: 4.5rem;
    font-weight: 300;
    padding-bottom: 2rem;
}

.header-content__left p{
    font-size: 15pt;
    font-weight: 200;
}

.header-content__left p, h1, button{
    color: #fff;
    font-family: var(--font-family);
}

.header-content__image{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-content__image img{
    width: 100%;
    max-width: 500px;
}

.header-content__button{
    display: flex;
    padding-top: 2rem;
    z-index: 3;
}

.header-content__button button{
    height: 3.5rem;
    padding: 0rem 1rem 0rem 1rem;
    color: black;
    background-color: #00C4CC;
    border: none;
    border-radius: 35px;
    font-size: 15pt;
    font-weight: 300;

    cursor: pointer;
}

.header-content__button a{
    font-weight: 400;
}

.header-content__button button:hover{
    background-color: #04b2b8;      
}

@media screen and (min-height: 1080px){
    .header-content{
        height: 90vh;
    }
}


@media screen and (max-width: 1185px){
    .header-content__left h1{
        font-size: 35pt;
        line-height: 2.75rem;
    }

    .header-content__left p{
        margin-top: -1.5rem;
        font-size: 12pt;
    }
}

@media screen and (max-width: 1050px){
    .header-content__left h1{
        font-size: 32pt;
    }
}


@media screen and (max-width: 1017px){
    .header-content__button button{
        margin-top: -1rem;

        height: 3rem;
        width: 10rem;
        font-size: 12pt;
        font-weight: 500;
    
    }
}

@media screen and (max-width: 994px){
    .header-content__left h1{
        font-size: 28pt;
    }
    .header-content__left p{
        font-size: 10pt;
    }
}

@media screen and (max-width: 994px){
    .header-content__left h1{
        font-size: 26pt;
    }
}

@media screen and (max-width: 700px){
    .header-content{
        padding: 4rem 4rem;
        height: 100%;
    }

    .left-section h1{
        margin-bottom: 0.5rem;
    }

    .header_content__todo{
        text-align: center;
    }

    .header-content__image{
        flex: 0;
        display: none;
    }

    .header-content__left{
        margin-right: 0px;
        justify-content: center;
        align-items: center;
    }

    .header-content__left h1{
        line-height: 2rem;
    }

    .left-section{
        align-items: center;
    }
}

@media screen and (max-width: 380px){
    .header-content{
        padding: 4rem 2rem;
    }

}

