.container-whatsapp{
    position: fixed;
    font-family: var(--font-family);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;

    right: 0;
    bottom: 0;
    margin-right: 10px;
    margin-bottom: 10px;

    background-color: rgb(63, 234, 95);
    border-radius: 50px;

    cursor: pointer;
    z-index: 100;

}

.container-whatsapp:hover{
    background-color: rgb(47, 226, 80);
}

.whatsapp-image{
    font-size: 28pt;
    color: white;
}

.whatsapp-notification{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;

    width: 25px;
    height: 25px;
    border-radius: 25px;

    font-size: 6pt;
    
    right: 0;
    top: 0;
    background-color: rgb(249, 0, 1);

    animation: scaleUp 1s linear infinite;
}

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scaleUp {
    0% {
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
      }
      50% {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
      }
      100% {
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
      }
  }
  @keyframes scaleUp {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
    50% {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
  }
  