*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a{
  color: unset;
  text-decoration: none;
}

.section__padding {
  padding: 4rem 8rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.gradient__bg{
  background: rgb(10,26,68);
  background: linear-gradient(90deg, rgba(10,26,68,1) 0%, rgba(44,120,161,1) 100%);
}

.gradient__bg-citacoes{
  background: rgb(0,196,204);
  background: linear-gradient(146deg, rgba(0,196,204,1) 0%, rgba(10,26,68,1) 100%);
}

