.container-footer{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    color: #ffffff;

    background-color:  #2C445F;
    font-family: var(--font-family);
}

.footer-content__todo{
    display: flex;
    width: 100%;
}

.footer-content{
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 1366px;
}

.footer-content__logo{
    flex: 1;
    display: flex;
    width: 100%;
}

.footer-content__logo img{
    width: 350px;
    margin-top: -60px;
}

.footer-content__middle{
    flex: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    
}

.middle-solucoes{
    display: flex;
    flex-direction: column;
    padding: 0px 30px 0px 30px;
}

.middle-sobre{
    flex: 2;
    display: flex;
    width: 140px;
}

.solucoes-links p{
    color: #ffffff;
    font-size: 14pt;
}

.footer-content-contact{
    flex: 1;
    display: flex;
    max-width: 300px;
    flex-direction: column;
    margin-left: 80px;
}

.footer-content-contact p{
    color: #ffffff;
    font-size: 14pt;
    padding-top: 20px;
    padding-bottom: 15px;
}

.contact-logos{
    display: flex;
    justify-content: space-between;
}

.contact-logos img{
    width: 50px;
}

.solucoes-links p:hover, .footer-content-contact a:hover {
    color: #c7c5c5;
}


@media screen and (max-width: 700px){
    .footer-content{
        padding: 2rem 2rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-content__logo{
        justify-content: center;
    }
    
    .footer-content-contact{
        margin-left: 0px;
        margin-top: 2rem;
        text-align: center;
    }

    .footer-content-contact p{
        padding-bottom: 1rem;
        padding-top: 0.5rem;
        font-weight: 500;
    }

    .footer-content__middle{
        width: 80%;
    }

    .footer-content__middle h2, .footer-content-contact h2{
        font-size: 15pt;
    }

    .footer-content__middle p, .footer-content-contact p{
        font-size: 12pt;
    }

    .middle-sobre{
        text-align: center    
    }

}

