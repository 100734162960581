.container-beneficiosCall{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: var(--font-family);
    color:  #2C445F;
}

.beneficiosCall-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.beneficiosCall-content h1{
    font-size: 48pt;
    -webkit-text-stroke: 2px  #2C78A1;
    margin-bottom: 3rem;
}

.content__sections{
    display: flex;
    justify-content: center;
    align-items: center;
}

.content__section-left{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.content__section-left h2{
    font-size: 28pt;
    color: #2C445F;
    -webkit-text-stroke: 0px;
    padding-bottom: 1rem;
}

.content__section-left p{
    font-size: 14pt;
    font-weight: 500;
    -webkit-text-stroke: 0px;
}

.content__section-right{
    flex: 0.65;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content__section-right img{
    width: 100%;
}


@media screen and (max-width: 700px){
    .beneficiosCall-content{
        padding: 2rem 2rem;
    }
    .content__sections{
        flex-direction: column;

    }
    .content__section-left{
        flex-direction: column;
        text-align: center;
        width: 100%;
    }
    .beneficiosCall-content h1{
        font-size: 32pt;
    }
    .content__section-left h2{
        font-size: 20pt;
    }

    ol{
        width: 100%;
    }

    li{
        width: 80%;
        margin-left: 3rem;
    }
}